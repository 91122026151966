import React from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import asyncComponent from './components/async'
import MainContainer from './containers/main-container'

const AsyncHomeContainer = asyncComponent(() => import('./containers/home-container'))
const AsyncResetContainer = asyncComponent(() => import('./containers/reset-container'))
const AsyncError404 = asyncComponent(() => import('./components/error-404'))

const App = () => (
    <MainContainer>
        <Router>
            <Switch>
                <Route exact path="/" component={AsyncHomeContainer} />
                <Route exact path="/reset/:token" component={AsyncResetContainer} />
                <Route component={AsyncError404} />
            </Switch>
        </Router>
    </MainContainer>
)

export default App
