import React, { Component } from 'react'
import PropTypes from 'prop-types'

const { instanceOf } = PropTypes

class MainContainer extends Component {
    static propTypes = {
        children: instanceOf(Object).isRequired
    }

    constructor(props) {
        super(props)
        this.state = {}
    }

    render() {
        return (
            <div className="main-container">
                {this.props.children}
            </div>
        )
    }
}

export default MainContainer
